import {
  Competencies,
  IBuilderCourse,
  IBuilderCourseCompetency,
  ICourseModel,
  IDictionary,
  TestType,
} from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { ISdkGetModerateCoursesRes } from '@unione-pro/unione.assmnt.sdk.webapp/lib/moderate/get-moderate-courses';
import { ICourse, ICourseStage } from '../models/stores/course.store';

export class ResponseMappers {

  public static getBuilderItem(
    response: IBuilderCourse<string, string>,
  ): IBuilderCourse<string> {
    const course: IBuilderCourse<string> = {
      ...response,
      files: response.files?.filter((file) => !!file.url),
      published_at: response.published_at || '',
    };
    return course;
  }

  public static getBuilderCourse(
    response: IBuilderCourse<string>,
    model: ICourseModel,
    dictionaries: IDictionary[],
  ): ICourse {
    const isSphere = model.test_options.test_type === TestType.sphere;
    const course: IBuilderCourse<string> = this.getBuilderItem(response);
    const courseExpertiseList = (isSphere ? course.spheres : course.competencies) || [];
    const expertiseList = courseExpertiseList.map((expert) => {
      const testing = course.testing.find((el) => el._id === expert._id)!;

      if (!isSphere) {
        const competencyExpert = expert as IBuilderCourseCompetency;
        return {
          ...testing,
          title: competencyExpert.title,
          description: competencyExpert[testing?.level as Competencies]?.description,
          tags: testing.tags,
        };
      }
      return { ...testing, title: expert.title, tags: testing.tags };
    });

    const stages: ICourseStage[] = course.stages.map((stage) => {
      const modelStage = model.stages.find((mStage) => mStage.order === stage.order);
      return {
        ...stage,
        name: modelStage!.name,
        color: modelStage!.color,
      };
    });

    const dictionary = dictionaries.find(
      (dict) => dict.id === course.dictionary_id,
    );

    return {
      ...course,
      expertiseList,
      model: {
        name: model.common_params.name,
        test_type: model.test_options.test_type,
      },
      stages,
      dictionary,
    };
  }

  public static getBuilderList(response: ISdkGetModerateCoursesRes): IBuilderCourse<string>[] {
    return response.items.map<IBuilderCourse<string>>(this.getBuilderItem);
  }

}
