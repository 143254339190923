import { FC, ReactElement } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { IModeration } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { StagesConstants } from '../../../../constants/stages.constants';
import { ICourse } from '../../../../models/stores/course.store';
import { toHumanDate } from '../../../../shared/human-date';
import { Hug } from '../../../components/hug';
import { Comment } from '../../course-readonly/components/comment';
import { EditCommentModal } from './edit-comment-modal';
import { Files } from './files';
import { ModerateField } from './moderate-field';
import { Stages } from './stages';
import { Testing } from './testing';
import { useModerateFormStyles } from './use-styles';
import { requiredStatusCheck } from './utils';

type IFieldProps = FieldRenderProps<IModeration>;

interface IModerateFormProps {
  course: ICourse;
}

export const ModerateForm: FC<IModerateFormProps> = ({ course }) => {
  const classes = useModerateFormStyles();

  const humanStage = StagesConstants[course.stage];
  const humanDate = toHumanDate(course.created_at);

  return (
    <div className={classes.form}>
      <EditCommentModal />

      <Paper className={cn(classes.paper, classes.fields)}>
        <div className={classes.header}>
          <Chip label={humanStage} color="primary" />
          <span>{humanDate}</span>
        </div>
        <Field
          name="title"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Название оценочной сессии"
              content={course.title}
            />
          )}
        />
        <Field
          name="academic.hours"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Количество академических часов"
              content={course.academic.hours}
            />
          )}
        />
        <Field
          name="desc"
          validate={requiredStatusCheck}
          render={(props: IFieldProps): ReactElement => (
            <ModerateField
              {...props}
              label="Описание оценочной сессии"
              content={
                course.desc ? (
                  <EditorPreview children={course.desc} />
                ) : undefined
              }
            />
          )}
        />
        <Field
          name="academic.month"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Сроки обучения"
              content={course.academic.month}
            />
          )}
        />
      </Paper>

      <Paper className={classes.paper}>
        <Hug
          title="Модель"
          className={classes.transparentBorder}
          contentClassName={classes.modelContent}
        >
          <Comment
            content={course.model.name ?? 'нет данных'}
          />
        </Hug>
      </Paper>

      <Paper className={cn(classes.paper, classes.fields)}>
        <Testing
          testType={course.model.test_type}
          expertiseList={course.expertiseList}
        />

        <Field
          name="program_year"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Поток"
              content={course.program_year?.value}
            />
          )}
        />
        <Field
          name="dictionary_id"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Отраслевая принадлежность"
              content={course.dictionary?.value}
            />
          )}
        />
        <Field
          name="is_it"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Выберите категорию обучающихся"
              content={course.is_it ? 'ИТ' : 'Не ИТ'}
            />
          )}
        />
        <Field
          name="qualification"
          validate={requiredStatusCheck}
          render={(props): ReactElement => (
            <ModerateField
              {...props}
              label="Наименование квалификации"
              content={course.qualification?.value}
            />
          )}
        />
      </Paper>

      <Files files={course.files} />

      <Paper className={classes.paper}>
        <Stages stages={course.stages} />
      </Paper>
    </div>
  );
};
