import { createUseStyles } from 'react-jss';
import { ModerationStatus } from '@unione-pro/unione.assmnt.sdk.webapp';

type RuleNames = 'wrapper' | 'secondaryText' | 'label' | 'valueColor';

interface IUSeCommentstatus {
  status?: ModerationStatus;
}

enum colorByStatus {
  approved = 'success',
  need_updates = 'warning',
}

export const useCommentStyles = createUseStyles<RuleNames, IUSeCommentstatus>(
  (theme) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      rowGap: 16,
      position: 'relative',
      wordBreak: 'break-word',
      padding: ({ status }): string => (status ? '16px 12px' : 'initial'),
      borderRadius: ({ status }): string => (status ? '4px' : 'initial'),
      borderWidth: ({ status }): number => (status ? 1 : 0),
      borderColor: ({ status }): string => (status ? theme.palette[colorByStatus[status]].main : 'initial'),
    },
    secondaryText: {
      color: theme.palette.secondaryText.main,
    },
    label: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.gray.contrastText,
    },
    valueColor: {
      color: ({ status }): string => (status ? theme.palette[colorByStatus[status]].main : theme.palette.gloomyBlue.main),
    },
  }),
  { name: 'Comment' },
);
