import {
  CourseStage,
  DictionaryType,
  ICourseModel,
  IDictionary,
  IFieldsDisplay,
  sdkGetCourseModel,
  sdkGetDictionaries,
  sdkGetModerateCourse,
  sdkRemoveCourseFromModeration,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_FIELDS_DISPLAY, OLD_PROGRAM_YEAR } from '../constants/course.constants';
import { ResponseMappers } from '../mappers/response.mappers';
import { ICourse, ICourseStore } from '../models/stores/course.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class CourseStore implements ICourseStore {

  public readonly rootStore: IRootStore;

  public data?: ICourse;
  public loading: boolean = false;
  public error?: string;
  public fieldsDisplay: IFieldsDisplay = DEFAULT_FIELDS_DISPLAY;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }


  public async getItem(courseId: string): Promise<void> {
    try {
      runInAction(() => {
        this.resetItem();
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetModerateCourse({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.user.token,
        data: { courseId },
      });

      const isOldDictionaries = response?.program_year && OLD_PROGRAM_YEAR.includes(response?.program_year?.value);
      const type = !isOldDictionaries && response?.program_year ? DictionaryType.industry24 : DictionaryType.industry;


      const [model, dictionaries] = await Promise.all([
        this.getModel(response.model_id),
        this.getDictionaries(type),
      ]);

      runInAction(() => {
        this.data = ResponseMappers.getBuilderCourse(response, model, dictionaries);
      });

      if (
        [CourseStage.needUpdates, CourseStage.moderate].some(
          (stage) => stage === response.stage,
        )
      ) {
        await this.rootStore.moderationCourses.get();
      }

      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.data = undefined;
        this.loading = false;
        this.error = getErrorMessage(error);
        this.fieldsDisplay = DEFAULT_FIELDS_DISPLAY;
      });
    }
  }


  public resetItem(): void {
    this.data = undefined;
    this.fieldsDisplay = DEFAULT_FIELDS_DISPLAY;
  }


  public async delete(courseId: string): Promise<void> {
    await sdkRemoveCourseFromModeration({
      baseURL: this.rootStore.config.builderAPI,
      token: this.rootStore.user.token,
      data: { courseId },
    });
  }

  public getModel = async(id: string): Promise<ICourseModel> => {
    const model = await sdkGetCourseModel({
      baseURL: this.rootStore.config.builderAPI,
      data: { id },
      token: this.rootStore.user.token,
    });

    this.fieldsDisplay = model.fields_display;

    return model;
  };

  public getDictionaries = async(type?: DictionaryType): Promise<IDictionary[]> => {
    const dataType = type || DictionaryType.industry;

    const response = await sdkGetDictionaries({
      baseURL: this.rootStore.config.builderAPI,
      token: this.rootStore.user.token,
      data: {
        type: dataType,
      },
    });

    return response.items;
  };

}
