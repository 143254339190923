import { FC, Fragment, ReactNode, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CourseStage,
  Competencies,
  TestType,
} from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { COMPETENCIES_LEVEL } from '../../../constants/course.constants';
import { getFileType } from '../../../constants/files.constants';
import { useIsIt } from '../../../hooks/use-is-it.hook';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useS3URL } from '../../../shared/use-s3-url';
import { appStoreContext } from '../../../stores/context.store';
import { FileRow } from '../../components/file-row';
import { Hug } from '../../components/hug';
import { NotificationsContext } from '../../components/notifications';
import { CourseStageHeader } from '../../components/stage';
import { CoursesReadonlyActions } from '../courses-actions';
import { Comment } from './components/comment';
import { Tags } from './components/tags';
import { ICourseReadonlyProps } from './course-readonly.models';
import { CoursesReadonlyStage } from './course-readonly.stage';
import { useCoursesReadonlyStyles } from './use-styles';

export const CourseReadonly: FC<ICourseReadonlyProps> = observer((props) => {
  const classes = useCoursesReadonlyStyles();
  const appStore = useContext(appStoreContext);
  const { course, coursesStage, moderationCourses, config, user } = appStore;
  const moderate = moderationCourses.initialValues;
  const { data, fieldsDisplay } = appStore.course;

  const isSphere = props.course?.model.test_type === TestType.sphere;

  const { getS3URL } = useS3URL({ baseURL: config.filesStorage, s3Token: user.s3Token });

  const notifyCenter = useContext(NotificationsContext);
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const isItToText = useIsIt();
  const getFileTypeCallback = useCallback(getFileType, []);

  const goToPrev = (): void => {
    navigate(-1);
  };

  const onNeedUpdates = async(): Promise<void> => {
    if (data && notifyCenter.push) {
      try {
        await coursesStage.updateStage({
          course_id: data._id,
          stage: CourseStage.moderate,
        });
        await course.getItem(data._id);
      }
      catch (error) {
        notifyCenter.push({
          title: 'Возникла ошибка',
          message: 'При попытке удалить оценочную сессию возникла ошибка, повторите попытку позже',
          type: 'error',
        });
      }
    }
  };

  const onArchive = async(): Promise<void> => {
    if (data && notifyCenter.push) {
      try {
        await coursesStage.updateStage({
          course_id: data._id,
          stage: CourseStage.archived,
        });
        notifyCenter.push({
          title: 'Оценочная сессия добавлена в архив',
        });
        navigate(BrowserRoute.coursesList);
      }
      catch (error) {
        const err = error as AxiosError<{ message: string }>;
        const altErrorMessage = 'При попытке добавления оценочной сессии в архив возникла ошибка, повторите попытку позже';
        const errorMessage = err.response?.data.message || altErrorMessage;
        notifyCenter.push({
          title: 'Возникла ошибка',
          message: errorMessage,
          type: 'error',
        });
      }
    }
  };

  const onDelete = async(): Promise<void> => {
    if (data && notifyCenter.push) {
      try {
        await course.delete(data._id);
        notifyCenter.push({
          title: 'Оценочная сессия успешно удалена!',
        });
        navigate({
          pathname: BrowserRoute.coursesList,
          search: `organization=${search.get('organization')}`,
        });
      }
      catch (error) {
        notifyCenter.push({
          title: 'Возникла ошибка',
          message: 'При попытке удалить оценочную сессию возникла ошибка, повторите попытку позже',
          type: 'error',
        });
      }
    }
  };

  const getStages = (): ReactNode[] => props.course.stages.map((stage): ReactNode => {
    const moderationStage = moderate?.stages?.find((ms) => ms?._id === stage._id);
    const stageOptions = {
      name: stage.name,
      color: stage.color,
      stage,
      moderation: moderationStage,
    };

    return <CoursesReadonlyStage key={stage._id} {...stageOptions} />;
  });

  useEffect(() => () => moderationCourses.reset(), [moderationCourses]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.course}>
        <div className={classes.header}>
          <CourseStageHeader
            color="helsinki"
            className={classes.gridColSpanFull}
            stage={props.course.stage}
            createdAt={props.course.created_at}
          />
          <div className={classes.headerTitleInfo}>
            <Comment
              content={
                <h1 className={classes.title}>
                  {props.course.title}
                </h1>
              }
              moderation={moderate?.title}
            />
            {fieldsDisplay.show_description && (
              <Comment
                content={props.course.desc}
                moderation={moderate?.desc}
              />
            )}
          </div>
          {fieldsDisplay.show_academic_hours && (
            <div className={classes.headerInfo}>
              <Comment
                label={'Академических часов'}
                content={
                  <div className={classes.timeContent}>
                    {props.course.academic.hours}
                  </div>
                }
                moderation={moderate?.academic?.hours}
              />
            </div>
          )}
          {fieldsDisplay.show_academic_months && (
            <div className={classes.headerInfo}>
              <Comment
                label={'Месяцев обучения'}
                content={
                  <div className={classes.timeContent}>
                    {props.course.academic.month}
                  </div>
                }
                moderation={moderate?.academic?.month}
              />
            </div>
          )}
        </div>
        <div className={classes.contentBlock}>
          <Hug
            title="Модель"
            className={classes.transparentBorder}
            contentClassName={classes.modelContent}
          >
            <Comment
              content={data?.model.name ?? 'нет данных'}
            />
          </Hug>
        </div>
        <div className={classes.contentBlock}>
          <Hug title={isSphere ? 'Сферы' : 'Компетенции'}>
            {props.course.expertiseList?.map((expertise, index) => (
              <Fragment key={expertise._id}>
                {index > 0 &&
                  <hr className={classes.expertiseSeparator} />
                }
                <div className={classes.contentWrapper} key={expertise._id}>
                  <div className={classes.content}>
                    <Comment
                      title={isSphere ? 'Сфера' : 'Компетенция'}
                      content={expertise.title}
                      moderation={moderate?.testing?.[index]}
                    />
                  </div>
                  <div className={classes.content}>
                    <div className={classes.grayLabel}>
                      Уровень: {
                        COMPETENCIES_LEVEL[expertise.level as Competencies]
                      }
                    </div>
                    <div>
                      {expertise?.description ?? ''}
                    </div>
                  </div>
                  <div className={classes.content}>
                    <Tags
                      expertise={expertise}
                      tagModeration={moderate?.testing?.[index]?.tags}
                      testing={props.course.testing}
                    />
                  </div>
                </div>
              </Fragment>
            ))}
          </Hug>
          <Hug title={fieldsDisplay.show_year ? 'Поток' : ''}>
            <div className={classes.contentWrapper}>
              {fieldsDisplay.show_year && (
                <div className={classes.content}>
                  <div className={classes.grayLabel}>
                    Поток
                  </div>
                  <Comment
                    content={props.course.program_year?.value}
                    moderation={moderate?.program_year}
                  />
                </div>
              )}
              {fieldsDisplay.show_branch_industry && (
                <div className={classes.content}>
                  <div className={classes.grayLabel}>
                    Отраслевая принадлежность
                  </div>
                  <Comment
                    content={props.course.dictionary?.value}
                    moderation={moderate?.dictionary_id}
                  />
                </div>
              )}
              {fieldsDisplay.show_it && (
                <div className={classes.content}>
                  <div className={classes.grayLabel}>
                    Выберите категорию обучающихся
                  </div>
                  <Comment
                    content={isItToText(props.course.is_it)}
                    moderation={moderate?.is_it}
                  />
                </div>
              )}
              {fieldsDisplay.show_qualification_name && (
                <div className={classes.content}>
                  <div className={classes.grayLabel}>
                    Наименование квалификации
                  </div>
                  <Comment
                    content={props.course.qualification?.value}
                    moderation={moderate?.qualification}
                  />
                </div>
              )}
            </div>
          </Hug>
          {fieldsDisplay.show_docs && (
            <div className={classes.files}>
              {props.course.files.map((file) => {
                const fileType = getFileTypeCallback(file.type);
                const fileURL = getS3URL(file.url);

                const moderationFile = moderate?.files?.find((mf) => mf.url === file.url);

                return (
                  <Comment
                    content={
                      <FileRow
                        key={file.url}
                        href={fileURL}
                        target="_blank"
                        name={fileType?.name}
                      />
                    }
                    moderation={moderationFile}
                  />
                );
              })}
            </div>
          )}
          {getStages()}
        </div>
      </div>
      <CoursesReadonlyActions
        course={data}
        needUpdatesProps={{
          disabled: props.course?.has_students,
        }}
        onClose={goToPrev}
        onArchived={onArchive}
        onDelete={onDelete}
        onNeedUpdates={onNeedUpdates}
      />
    </div>
  );
});
